<template>
  <div>
    <div style="margin: 10px 0">
<!--      <el-input style="width: 200px" placeholder="请输入购买人姓名" suffix-icon="el-icon-search" v-model="name"></el-input>-->
<!--      <el-input style="width: 200px" placeholder="请输入" suffix-icon="el-icon-message" class="ml-5" v-model="email"></el-input>-->
<!--      <el-input style="width: 200px" placeholder="请输入" suffix-icon="el-icon-position" class="ml-5" v-model="address"></el-input>-->
<!--      <el-button class="ml-5" type="primary" @click="load">搜索</el-button>-->
<!--      <el-button type="warning" @click="reset">重置</el-button>-->
    </div>

    <div style="margin: 10px 0">
<!--      <el-button type="primary" @click="handleAdd">新增 <i class="el-icon-circle-plus-outline"></i></el-button>-->
      <!-- <el-upload action="http://localhost:9090/applcation/import" :show-file-list="false" accept="xlsx" :on-success="handleExcelImportSuccess" style="display: inline-block">
        <el-button type="primary" class="ml-5">导入 <i class="el-icon-bottom"></i></el-button>
      </el-upload>
      <el-button type="primary" @click="exp" class="ml-5">导出 <i class="el-icon-top"></i></el-button> -->
    </div>

    <el-table :data="tableData" border stripe :header-cell-class-name="'headerBg'"  @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column label="图片"><template slot-scope="scope"><el-image style="width: 100px; height: 100px" :src="scope.row.products.img" :preview-src-list="[scope.row.products.img]"></el-image></template></el-table-column>

      <el-table-column prop="products.name" label="宠物用品"></el-table-column>
      <el-table-column prop="products.price" label="价格"></el-table-column>
      <el-table-column prop="phone" label="联系方式"></el-table-column>
      <el-table-column prop="totalAmount" label="金额"></el-table-column>
      <el-table-column prop="preferential" label="优惠金额"></el-table-column>
      <el-table-column label="配送方式">
        <template slot-scope="scope">
          {{ scope.row.deliveryMethod }}

          <!-- 显示地址和电话，仅当 deliveryMethod 为 '上门' 时 -->
          <div v-if="scope.row.deliveryMethod === '邮寄'">
            <b >地址：</b>{{ scope.row.address }}
            <b >收件人：</b>{{ scope.row.recipient }}
          </div>
        </template>
      </el-table-column>
<!--      <el-table-column prop="pet" label="宠物"></el-table-column>-->
      <el-table-column prop="phone" label="联系方式"></el-table-column>
      <el-table-column prop="paymentMethod" label="付款方式"></el-table-column>
      <el-table-column prop="number" label="购买数量"></el-table-column>
      <el-table-column label="状态">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.opStatus==='已下单'" type="info">{{scope.row.opStatus}}</el-tag>
          <el-tag v-if="scope.row.opStatus==='处理中'" type="primary">{{scope.row.opStatus}}</el-tag>
          <el-tag v-if="scope.row.opStatus==='已完成'" type="success">{{scope.row.opStatus}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作"  width="280" align="center">
        <template slot-scope="scope">
          <el-button type="success" @click="changeOpStatus(scope.row, '处理中')" >处理中 <i class="el-icon-check"></i></el-button>
          <el-button type="primary" @click="changeOpStatus(scope.row, '已完成')">已完成 <i class="el-icon-check"></i></el-button>
        </template>
      </el-table-column>
    </el-table>
    <div style="padding: 10px 0">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageNum"
          :page-sizes="[2, 5, 10, 20]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductsBuy",
  data() {
    return {
      tableData: [],
      total: 0,
      pageNum: 1,
      pageSize: 10,
      name: "",
      form: {},
      dialogFormVisible: false,
      multipleSelection: [],
      user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : {}
    }
  },
  created() {
    this.load()
  },
  methods: {
    changeOpStatus(row,state){
      this.request.post("/productsBuy/opstatus/" + row.id + "/" + state).then(res => {
        this.$message.success("操作成功")
        this.load()
      })
    },
    load() {
      this.request.get("/productsBuy/page", {
        params: {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          name: this.name,
        }
      }).then(res => {
        this.tableData = res.data.records
        this.total = res.data.total
      })
    },
    save() {
      this.request.post("/productsBuy", this.form).then(res => {
        if (res.code === '200') {
          this.$message.success("保存成功")
          this.dialogFormVisible = false
          this.load()
        } else {
          this.$message.error("保存失败")
        }
      })
    },
    handleAdd() {
      this.dialogFormVisible = true
      this.form = {}
      this.$nextTick(() => {
        if(this.$refs.img) {
           this.$refs.img.clearFiles();
         }
         if(this.$refs.file) {
          this.$refs.file.clearFiles();
         }
      })
    },
    handleEdit(row) {
      this.form = JSON.parse(JSON.stringify(row))
      this.dialogFormVisible = true
       this.$nextTick(() => {
         if(this.$refs.img) {
           this.$refs.img.clearFiles();
         }
         if(this.$refs.file) {
          this.$refs.file.clearFiles();
         }
       })
    },
    del(id) {
      this.request.delete("/productsBuy/" + id).then(res => {
        if (res.code === '200') {
          this.$message.success("删除成功")
          this.load()
        } else {
          this.$message.error("删除失败")
        }
      })
    },
    handleSelectionChange(val) {
      console.log(val)
      this.multipleSelection = val
    },
    delBatch() {
      if (!this.multipleSelection.length) {
        this.$message.error("请选择需要删除的数据")
        return
      }
      let ids = this.multipleSelection.map(v => v.id)  // [{}, {}, {}] => [1,2,3]
      this.request.post("/productsBuy/del/batch", ids).then(res => {
        if (res.code === '200') {
          this.$message.success("批量删除成功")
          this.load()
        } else {
          this.$message.error("批量删除失败")
        }
      })
    },
    reset() {
      this.name = ""
      this.load()
    },
    handleSizeChange(pageSize) {
      console.log(pageSize)
      this.pageSize = pageSize
      this.load()
    },
    handleCurrentChange(pageNum) {
      console.log(pageNum)
      this.pageNum = pageNum
      this.load()
    },
    handleFileUploadSuccess(res) {
      this.form.file = res
    },
    handleImgUploadSuccess(res) {
      this.form.img = res
    },
    download(url) {
      window.open(url)
    },
    exp() {
      window.open(process.env.VUE_APP_BASE_URL+"applcation/export")
    },
    handleExcelImportSuccess() {
      this.$message.success("导入成功")
      this.load()
    }
  }
}
</script>


<style>
.headerBg {
  background: #eee!important;
}
</style>
